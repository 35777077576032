import "./App.css";
import React, { useEffect, useState } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "./Pages/Login";
import LandingPage from "./Pages/LandingPage";
import Pesdata from "./Pages/Pesdata";
import "react-notifications/lib/notifications.css";
import PesPrint from "./Pages/PesPrint";
import Layout from "./Pages/Layout";
import VerificationCode from "./Pages/VerificationCode";
import PasswordRecovery from "./Pages/PasswordRecovery";
import NewPassword from "./Pages/NewPassword";
import PesModifyRecord from "./Pages/PesModifyRecord";
import EditPesdata from "./Pages/EditPesdata";
import AftercareList from "./Pages/AftercareList";
import PrivacyStatement from "./Pages/PrivacyStatement";
import FrenchandQuebecDocuments from "./Pages/FrenchandQuebecDocuments";
import Training from "./Pages/Training";
import useInactivity from "./utils/useInactivity";
import { useIdleTimer } from "react-idle-timer";
import LandingPageOld from "./Pages/LandingPageOld";
import DOA from "./Pages/DOA";
import DOALogin from "./Pages/DOALogin";
import DOAReports from "./Pages/DOAReports";
import DOASearch from "./Pages/DOASearch";
import ComingSoon from "./Pages/ComingSoon";
import DOAUpdate from "./Pages/DOAUpdate";
import AftercareList365 from "./Pages/AftercareList365";
import AdminUsers from "./Pages/AdminUsers";
function App() {

  useInactivity(30 * 60 * 1000);

  const [state, setState] = useState("Active");
  const [count, setCount] = useState(0);
  const [remaining, setRemaining] = useState(0);
  // console.log("remaining", remaining);
  const onIdle = () => {
    setState("Idle");
    NotificationManager.error(
      "For security reasons your session has been terminated due to inactivity. Please Login.",
      ""
    );
    localStorage.removeItem("userData");
  };

  const onActive = () => {
    setState("Active");
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 1800_000,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <>
      {" "}
      <NotificationContainer />
      <></>
      <div>
        <BrowserRouter>
          <Layout>
            <Routes>
              {" "}
              <Route path="/Executorcomingsoon" element={<ComingSoon />} />
              <Route path="/doalogin" element={<DOALogin />} />
              <Route path="/doaadminusers" element={<AdminUsers />} />
              <Route path="/doaupdate/:eventID" element={<DOAUpdate />} />
              <Route path="/doasearch" element={<DOASearch />} />
              <Route path="/doa" element={<DOA />} />
              <Route path="/doareports" element={<DOAReports />} />
              <Route path="/" element={<LandingPage />} />
              <Route path="/landingold" element={<LandingPageOld />} />
              {/* <header> */}
              <>
                {JSON.parse(localStorage.getItem("userData")) &&
                  JSON.parse(localStorage.getItem("userData")).RoutingID && (
                    // {true && (
                    <>
                      <Route path="/training" element={<Training />} />

                      <Route
                        path="/aftercare"
                        element={<AftercareList />}
                      />
                      <Route
                        path="/aftercare365"
                        element={<AftercareList365 />}
                      />
                      <Route
                        path="/modify"
                        element={<PesModifyRecord />}
                      />
                      <Route
                        path="/modifyRecord/:eventID"
                        element={<EditPesdata />}
                      />
                      <Route
                        path="/PasswordRecovery"
                        element={<PasswordRecovery />}
                      />
                      <Route
                        path="/VerificationCode"
                        element={<VerificationCode />}
                      />
                      <Route path="/NewPassword" element={<NewPassword />} />
                      <Route
                        path="/PrivacyStatement"
                        element={<PrivacyStatement />}
                      />
                      <Route path="/executorPrint" element={<PesPrint />} />
                      <Route path="/input" element={<Pesdata />} />
                      <Route
                        path="/FrenchandQuebecDocuments"
                        element={<FrenchandQuebecDocuments />}
                      />
                    </>
                  )}
                <Route path="*" element={<Navigate to="/" replace />} />
              </>
            </Routes>
          </Layout>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
